import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Patent`}</h1>
    <ul>
      <li parentName="ul">{`System and methods for real-time formation of groups and decentralized decision making`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://patents.google.com/patent/US20150089399A1/"
        }}>{`https://patents.google.com/patent/US20150089399A1/`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      